import React, { useState, useEffect } from "react";
import { Nav } from "react-bootstrap";
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useLocation, useNavigate } from "react-router-dom";

const DashboardNavbar = () => {
    const [show, setShow] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        setShow(false);
    }, [location.pathname]);

    return (
        <React.Fragment>
            <Navbar expand={false} className="navbar navbar-dark px-2 px-md-3" style={{ height: '62px', backgroundColor: '#d04545', justifyContent: 'flex-start' }}>
                <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setShow(true)} />
                <Navbar.Brand className="ms-4">Fascinating Diamonds</Navbar.Brand>

                <Navbar.Offcanvas
                    show={show}
                    onHide={() => setShow(false)}
                    id="offcanvasNavbar-expand"
                    aria-labelledby="offcanvasNavbarLabel-expand"
                    placement="start"
                    className="shadow-lg"
                >
                    <Offcanvas.Header closeButton style={{ backgroundColor: '#d04545' }}>
                        <Offcanvas.Title id="offcanvasNavbarLabel-expand" className="text-light">
                            Fascinating Diamond
                        </Offcanvas.Title>
                    </Offcanvas.Header>

                    <Offcanvas.Body className="p-0">
                        <Nav className="justify-content-end flex-grow-1">
                            <Nav.Link className={`border-bottom p-3 cursor-pointer ${location.pathname === '/products/sync' ? 'active-nav-link' : ''}`} onClick={() => navigate("/products/sync")} as="div">
                                Product Sync
                            </Nav.Link>

                            <Nav.Link className={`border-bottom p-3 cursor-pointer ${location.pathname === '/category/sync' ? 'active-nav-link' : ''}`} onClick={() => navigate("/category/sync")} as="div">
                                Category
                            </Nav.Link>
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Navbar>
        </React.Fragment>
    );
}

export default DashboardNavbar;
