import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button, Container, Form, Spinner } from "react-bootstrap";
import Api from "../../utils/api";
import { toast } from "react-toastify";
import Select from 'react-select';

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderColor: state.isFocused ? "#d04545" : "#ccc",
        color: "#333",
        "&:hover": { borderColor: "#d04545" },
        boxShadow: "none"
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: "#ffffff",
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? "#d04545" : state.isFocused ? "#f8d7da" : "#ffffff",
    }),
};


const SyncCategory = () => {
    const envOptions = [
        {
            label: "Development",
            value: "dev"
        },
        {
            label: "Production",
            value: "prod"
        }
    ]

    const [categoryList, setCategoryList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState();
    const [selectedEnv, setSelectedEnv] = useState({
        label: "Development",
        value: "dev"
    });
    const [isLoading, setIsLoading] = useState(false);
    const [pageSlug, setPageSlug] = useState("");
    const [pageSlugAliasesInputValue, setPageSlugAliasInputValue] = useState("");
    const [refetchPageSlug, setRefetchPageSlug] = useState(false)

    useEffect(() => {
        if (selectedEnv?.value) {
            setIsLoading(true);
            setCategoryList([])
            Api({
                method: "get",  
                url: `/category-listing?env=${selectedEnv?.value}`,
            })
                .then((res) => {
                    const categoryList = res.data.Data.message.data

                    const categoryOptionList = categoryList?.map((cat) => {
                        return {
                            label: cat.category_name,
                            value: cat.id,
                            category_url: cat.category_url
                        }
                    })
                    setCategoryList(categoryOptionList)
                })
                .catch((err) => {
                    toast.error(err.response.data.Error.message);
                })
                .finally(() => {
                    setIsLoading(false);
                    setRefetchPageSlug(true)
                });
        }
    }, [selectedEnv?.value]);

    const fetchCategoryPageSlugAlias = () => {
        setPageSlug('')
        setPageSlugAliasInputValue([])
        setIsLoading(true);
        Api({
            method: "get",
            url: `/category-page-slugs/${selectedCategory}?env=${selectedEnv?.value}`,
        })
            .then((res) => {
                const { pageSlug, pageSlugAliases } = res.data.Data.message.data;
                setPageSlug(pageSlug);
                setPageSlugAliasInputValue(pageSlugAliases.join(",\n"));
            })
            .catch((err) => {
                if (err?.response?.status === 400 && err.response.data.Error.message === "Page slug does not exist.") {
                    const selectedCategoryObj = categoryList?.find((cat) => cat?.value === selectedCategory)
                    setPageSlug(selectedCategoryObj?.category_url)
                }
                toast.error(err.response.data.Error.message);
            })
            .finally(() => {
                setIsLoading(false)
                setRefetchPageSlug(false)
            });
    };

    useEffect(() => {
        if (selectedCategory && selectedEnv && refetchPageSlug) {
            fetchCategoryPageSlugAlias();
        }
    }, [selectedEnv?.value, selectedCategory, refetchPageSlug]);

    const handleAliasInputChange = (e) => {
        let value = e.target.value;
        setPageSlugAliasInputValue(value);
    };

    const handleAliasKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();

            setPageSlugAliasInputValue((prev) => {
                const trimmedPrev = prev.trim();

                if (trimmedPrev.endsWith(",")) {
                    return trimmedPrev + "\n";
                }
                return trimmedPrev + ",\n";
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (pageSlugAliasesInputValue?.length) {
            const formattedAliases = pageSlugAliasesInputValue?.split(/,\s*\n+/)?.map((alias) => alias.trim())?.filter(Boolean);
            Api({
                method: "put",
                url: `/category-page-slugs/${selectedCategory}?env=${selectedEnv?.value}`,
                body: {
                    page_slug: pageSlug,
                    page_slug_aliases: formattedAliases
                }
            })
                .then((res) => {
                    toast.success('Category sync started successfully');
                })
                .catch((err) => {
                    toast.error(err.response.data.Error.message);
                })
                .finally(() => setIsLoading(false));
        }
    };

    const handleEnvChange = (selectedValueObj) => {
        setSelectedEnv(selectedValueObj); 
    }

    const handleCatChange= (selectedValueObj) => {
        setSelectedCategory(selectedValueObj.value); 
        setRefetchPageSlug(true)
    }

    return (
        <div className="position-relative">
            <Container>
                <Form className="mt-5 w-100" onSubmit={handleSubmit}>
                    <div className="d-flex gap-5 w-100">
                        <Form.Group className="mb-3 w-25">
                            <Form.Label>Choose Environment</Form.Label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                isSearchable={true}
                                name="enviornment"
                                options={envOptions}
                                value={selectedEnv}
                                placeholder="Choose Enviornment"
                                onChange={(selectedEnvObj) => {handleEnvChange(selectedEnvObj)}}
                                styles={customStyles}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3 w-25">
                            <Form.Label>Choose Category</Form.Label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                isSearchable={true}
                                name="category"
                                options={categoryList}
                                placeholder="Choose Category"
                                onChange={(selectedCatObj) => {handleCatChange(selectedCatObj)}}
                                styles={customStyles}
                            />
                        </Form.Group>
                    </div>

                    <div className="d-flex align-items-top justify-content-between gap-5 mt-4 flex-wrap">
                        <Form.Group style={{ width: "40%" }}>
                            <Form.Label>Page Slug</Form.Label>
                            <Form.Control
                                value={pageSlug || ""}
                                as="textarea"
                                style={{ resize: "none", height: "calc(100dvh - 400px)" }}
                                onChange={(e) => setPageSlug(e.target.value)}
                                readOnly
                            />
                        </Form.Group>

                        <Form.Group style={{ width: "40%" }}>
                            <Form.Label>Page Slug Aliases</Form.Label>
                            <Form.Control
                                value={pageSlugAliasesInputValue}
                                as="textarea"
                                style={{ resize: "none", height: "calc(100dvh - 400px)" }}
                                onChange={handleAliasInputChange}
                                onKeyDown={handleAliasKeyDown}
                            />
                        </Form.Group>
                    </div>

                    <div className="d-flex justify-content-center mt-4">
                        <Button variant="danger" type="submit" className="px-4">Submit</Button>
                    </div>
                </Form>
            </Container>

            {isLoading && (
                <div className="loader">
                    <Spinner animation="border" variant="danger" />
                </div>
            )}
        </div>
    );
};

export default SyncCategory;
