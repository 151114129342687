
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import DashboardFooter from "./components/footer/Footer";
import DashboardNavbar from "./components/navbar/Navbar";
function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const checkUserToken = () => {
    const userToken = localStorage.getItem('user-token');
    if (!userToken || userToken === 'undefined') {
      setIsLoggedIn(false);
    }
    setIsLoggedIn(true);
  }
  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn]);

  return (
      <React.Fragment>
        {isLoggedIn && <DashboardNavbar />}
        <Outlet />
        {isLoggedIn && <DashboardFooter />}
      </React.Fragment>
  );
}
export default App;
